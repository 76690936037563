import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Ado = ({ data }) => {
  const sexualite = <><b>Je révèle ma sexualité,</b></>;
  const prereq = <><b style={{ color:"green" }}>Aucun prérequis équestre n'est nécessaire, ON NE MONTE PAS LE CHEVAL.</b></>;
  
  return (
    <Layout>
      <SEO title="Adolescents" />
      <Header metadata={data.site.siteMetadata} />
        {prereq} <br/><br/>   
        Je propose de les soutenirs dans la résolution de leurs difficultés de vie:<br/><br/>
        * Blocage scolaire <br/>
        * Manque de confiance en soi <br/>
        * Problèmes relationnels <br/>
        * Gestion des émotions <br/>
        * Traumatismes et phobies <br/><br/>
        L'adolescence est une période de la vie au cours de laquelle le jeune cherche à se définir par rapport aux autres. <b>S'interroge sur lui-même. </b>
        Dans son intimité, la découverte de sa sexualité peut être source de questionnement et d'interrogation. <br/><br/>
        Pour ça je leur propose: <br/>
        {sexualite} avec comme ligne directrice: <b>qui suis-je?</b><br/><br/>
        * Comment je me respecte?<br/>
        * Comment je me fais respecter?<br/>
        * Comment je respecte les autres?<br/>
        * Qu'est-ce que j'accepte ou pas?<br/><br/>
      </Layout>
  );
};

export default Ado;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
